import Vue from 'vue';
import VCalendar from 'v-calendar';
import Wrapper from '@/js/router/Wrapper.vue';
import countries from 'i18n-iso-countries';
import VTooltip from 'v-tooltip';
import router from './router';
import store from './store';
import axiosSetup from './axiosSetup';

// Setup access token validation
axiosSetup();

Vue.use(VCalendar, {
    locales: {
        'de-DE': {
            L: 'DD.MM.YYYY',
            inputDateTime24hr: 'L HH:mm',
        },
    },
});
Vue.use(VTooltip);

const app = new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(Wrapper),
});

countries.registerLocale(require('i18n-iso-countries/langs/de.json'));

export default app;
